<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <ScheduleList/>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import ScheduleList from './Schedule'

export default {
  name: "Index",
  components: {
    PageHeaderLayout, ScheduleList
  }

}
</script>

<style scoped>

</style>
